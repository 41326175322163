import { Component, h } from 'preact'
import { Track } from './components/Track'
import './css/app.scss'

export interface IAppProps {}

export interface IAppState {
  token: string
  tracks: any
  term: string
}

export class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props)
    this.initToken()
    this.setState({ tracks: [], term: 'long_term' })
  }

  public render(props: IAppProps, state: IAppState) {
    return (
      <div style="max-width: 700px;width:100%; margin-left: auto; margin-right: auto;">
        <div class="term-select">
          <div
            onclick={this.termSelect.bind(this)}
            id="long_term"
            class={this.state.term === 'long_term' ? 'active' : ''}
          >
            all time
          </div>
          <div
            onclick={this.termSelect.bind(this)}
            id="medium_term"
            class={this.state.term === 'medium_term' ? 'active' : ''}
          >
            six months
          </div>
          <div
            onclick={this.termSelect.bind(this)}
            id="short_term"
            class={this.state.term === 'short_term' ? 'active' : ''}
          >
            month
          </div>
        </div>
        {this.state.tracks.map(track => {
          return (
            <div>
              <Track track={track} />
            </div>
          )
        })}
      </div>
    )
  }

  public componentDidMount() {
    this.getTracks(this.state.term)
  }

  public componentWillUpdate() {
    if (!this.state.token) {
      this.initToken()
    }
  }

  public componentWillUnmount() {}

  private async getTracks(term) {
    try {
      const tracks = await fetch(
        `https://api.spotify.com/v1/me/top/tracks?time_range=${term}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.state.token,
          },
        }
      ).then(res => res.json())
      this.setState({ tracks: tracks.items, term })
    } catch (error) {
      this.setState({ token: '' })
    }
  }

  private initToken() {
    if (this.state.token) {
      return
    }
    let token = window.localStorage.getItem('spovis_acess_token')
    if (!token) {
      token = new URLSearchParams(window.location.hash.substring(1)).get(
        'access_token'
      )
      if (!token) {
        this.authRedirect()
      } else {
        const currentState = history.state
        history.replaceState(currentState, 'Spovis', '/')
        window.localStorage.setItem('spovis_access_token', token)
        this.setState({ token })
      }
    }
  }

  private authRedirect() {
    window.location.href =
      'https://accounts.spotify.com/authorize?client_id=422182e4f45c4ef283a59847ad04de51&redirect_uri=https://spotify.feryla.net&scope=user-top-read&response_type=token&state=123'
  }

  private termSelect(e) {
    const term = e.target.id
    this.getTracks(term)
  }
}
