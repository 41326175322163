import { Component, h } from 'preact'

interface IProps {
  track: any
}

export class Track extends Component<IProps, any> {
  constructor(props: any) {
    super(props)
  }

  public render(props: any, state: any) {
    return (
      <div>
        <div class="title">{this.props.track.name}</div>
        <div class="artist">
          {this.props.track.artists.map(artist => (
            <span style="margin-right:10px;">{artist.name}</span>
          ))}
        </div>
      </div>
    )
  }

  public componentDidMount() {}

  public componentWillUnmount() {}
}
